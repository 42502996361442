/**
 * Basic typography style for copy text
 */

@use '../abstracts/mixins' as *;

body {
  font-size: var(--fs-400);
  font-family: var(--ff-helv-light);
  line-height: 1.5;
  color: var(--primary);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: normal;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;

  @include devices('mobile') {
    hyphens: none;
    -moz-hyphens: none;
    -webkit-hyphens: none;
    -ms-hyphens: none;
  }
}

.ff-cy-bold {
  font-family: var(--ff-cy-bold);
}

.ff-cy-light {
  font-family: var(--ff-cy-light);
}

.ff-helv-bold {
  font-family: var(--ff-helv-bold);
}

.ff-helv-light {
  font-family: var(--ff-helv-light);
}

.fs-400 {
  font-size: var(--fs-400);
}

.fs-500 {
  font-size: var(--fs-500);
}

.fs-600 {
  font-size: var(--fs-600);
}

.fs-700 {
  font-size: var(--fs-700);
}

.centered {
  text-align: center;
}

strong {
  font-family: var(--ff-helv-bold);
  @include letter-spacing(50);
}

.line-height-1 {
  line-height: 1.5;
}

.line-height-2 {
  line-height: 1.25;
}

.line-height-3 {
  line-height: 1.05;
}

.uppercase {
  text-transform: uppercase;
}

.bounce {
  display: block;
  width: fit-content;
  height: fit-content;
  transform: scale(1);
  transition: transform 0.2s cubic-bezier(0.47, 1.64, 0.41, 0.8);
  &:hover {
    transform: scale(1.1);
  }
}
