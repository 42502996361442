@use '../abstracts/' as *;

.cookie-banner {
    background-color: var(--secondary);
    padding: 0.75rem 1rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @include devices(mobile) {
        bottom: var(--padding);
        right: var(--padding);
        max-width: 345px;
    }

    &__text {
        margin-bottom: 0.75rem;
        font-size: 0.875rem;
        @include letter-spacing(15);
    }

    &__preferences {
        display: flex;
        gap: 1rem;
    }

    &__preference {
        flex: 1;
        &-button {
            width: 100%;
            font-size: 1rem;
            padding: 0.45em 1em;
            cursor: pointer;
            font-family: var(--ff-helv-bold);
            @include letter-spacing(20);
        }

        &-button[aria-label='accept'] {
            background-color: var(--accent-1);
            color: var(--primary);

            border: 1px solid var(--accent-1);

            &:hover {
                background-color: var(--secondary);
                color: var(--accent-1);
            }
        }

        &-button[aria-label='decline'] {
            background-color: var(--secondary);
            color: var(--primary);

            border: 1px solid var(--accent-1);

            &:hover {
                color: var(--accent-1);
            }
        }
    }
}
