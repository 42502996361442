// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

@use '../abstracts/mixins' as *;

.site-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    background-color: var(--secondary);
    padding: var(--padding);

    &__copyright {
        font-size: var(--fs-300);
    }

    &__navigation {
        display: flex;
        gap: 1rem;
        font-size: var(--fs-300);
        color: var(--primary);
        a {
            text-decoration: none;
        }
    }
}
