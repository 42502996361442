// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

@use 'mixins' as *;

:root {
  --max-width: 1600px;
  --padding: 1rem;

  @include devices(tablet) {
    --padding: 2rem;
  }

  @include devices(desktop) {
    --padding: 4rem;
  }

  --ff-cy-bold: 'Cy-SemiBold', sans-serif;
  --ff-cy-light: 'Cy-SemiLight', sans-serif;
  --ff-helv-bold: 'HelveticaNowDisplay-Bold', sans-serif;
  --ff-helv-light: 'HelveticaNowDisplay-Light', sans-serif;

  --fs-300: 1rem;
  --fs-350: 1.25rem;
  --fs-400: clamp(1.25rem, 0.9375rem + 1.5625vw, 2.5rem);
  --fs-500: clamp(1.5rem, 0.7813rem + 3.5937vw, 4.375rem);
  --fs-600: clamp(1.75rem, 0.7813rem + 4.8438vw, 5.625rem);
  --fs-700: clamp(2rem, 0.8rem + 6vw, 8rem);

  --primary: #fff;
  --secondary: #4100d3;
  --accent-1: #ff00f5;
}
