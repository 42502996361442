// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
    font-family: 'Cy-SemiBold';
    src: url('../../assets/fonts/Cy-SemiBold.woff2') format('woff2'),
        /* Add WOFF2 first */ url('../../assets/fonts/Cy-SemiBold.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Cy-SemiLight';
    src: url('../../assets/fonts/Cy-SemiLight.woff2') format('woff2'),
        /* Add WOFF2 first */ url('../../assets/fonts/Cy-SemiLight.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay-Bold';
    src: url('../../assets/fonts/HelveticaNowDisplay-Bold.woff2') format('woff2'),
        /* Add WOFF2 first */ url('../../assets/fonts/HelveticaNowDisplay-Bold.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay-Light';
    src: url('../../assets/fonts/HelveticaNowDisplay-Light.woff2') format('woff2'),
        /* Add WOFF2 first */ url('../../assets/fonts/HelveticaNowDisplay-Light.otf') format('opentype');
    font-display: swap;
}
