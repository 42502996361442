@use '../abstracts/mixins' as *;

.login-page {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include min-vh(100);

        .card {
            max-width: 300px;
            &__form {
                display: flex;
                flex-direction: column;
                row-gap: 1rem;
            }
        }
    }
}
