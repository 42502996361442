// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

@use '../abstracts/' as *;

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */

html {
  overscroll-behavior: none;
}

body {
  background-color: var(--secondary);
}

.route-container {
  position: absolute;
  width: 100%;
  z-index: -1;
  will-change: transform, opacity;
}

.loading {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: var(--secondary);
  animation: fadeOut 200ms ease-in-out forwards;

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.site-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  will-change: opacity;
}

.offset {
  margin-top: 100px;
}

// Container
.container {
  width: 100%;
  max-width: var(--max-width);
  padding: var(--padding);

  &.center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.fluid {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 0;

  img {
    object-fit: cover;
  }
}

img,
video {
  display: block;
  width: 100%;
  max-width: 100%;
}

.visually-hidden {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(1px, 1px, 1px, 1px); /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(50%); /*modern browsers, clip-path works inwards from each corner*/
  white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
}

a {
  color: inherit;
}

.m-1 {
  margin-bottom: 1em;
}

.m-2 {
  margin-bottom: 2em;
}

.m-3 {
  margin-bottom: 3em;
}

.m-4 {
  margin-bottom: 4em;
}
