// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

@use '../abstracts/mixins' as *;

.home {
    // background-color: var(--secondary);

    .hero {
        padding: var(--padding);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @include min-vh(100);

        background: linear-gradient(0deg, rgba(65, 0, 211, 1) 0%, rgba(65, 0, 211, 0) 15%, rgba(65, 0, 211, 0) 100%);

        &__video {
            position: fixed;
            width: 100%;
            inset: 0;
            z-index: -1;
            object-fit: cover;
            @include vh(100);
        }

        &__content {
            position: relative;
            z-index: 1;
        }

        &__headline {
            .words {
                color: var(--accent-1);

                .word {
                    position: absolute;
                    opacity: 0;
                    transform: translateY(-100%);
                    transition: transform 175ms ease-in-out, opacity 175ms ease-in-out;

                    &--enter {
                        opacity: 1;
                        animation: wordEnter 0.5s ease-in-out forwards;
                    }

                    &--exit {
                        opacity: 0;
                        animation: wordExit 0.3s ease-in-out forwards;
                    }

                    @keyframes wordEnter {
                        from {
                            opacity: 0;
                            transform: translateY(-25%);
                        }
                        to {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }

                    @keyframes wordExit {
                        from {
                            opacity: 1;
                            transform: translateY(0);
                        }
                        to {
                            opacity: 0;
                            transform: translateY(25%);
                        }
                    }
                }
            }
        }
    }

    .intro {
        padding: var(--padding);
        background-color: var(--secondary);

        &__content {
            max-width: 1000px;
        }
    }

    .services {
        padding: var(--padding);
        background-color: var(--secondary);
        isolation: isolate;
        position: relative;
        z-index: 10;

        &__item {
            position: relative;
            margin-bottom: 4rem;

            .title {
                cursor: crosshair;
            }

            p {
                display: none;
            }

            img,
            video {
                border-radius: 5px;
            }

            @include devices(tablet) {
                margin-bottom: 0;
            }
        }
    }

    .about {
        position: relative;
        z-index: 5;

        &__video {
            position: absolute;
            width: 100%;
            height: 100%;
            inset: 0;
            object-fit: cover;
        }

        &__content {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 1;
            padding: var(--padding);
            color: var(--accent-1);
            @include min-vh(100);
        }

        &__text {
            max-width: 1000px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            inset: 0;
            z-index: 10;
            background: linear-gradient(
                0deg,
                rgba(65, 0, 211, 1) 0%,
                rgba(65, 0, 211, 0) 15%,
                rgba(65, 0, 211, 0) 85%,
                rgba(65, 0, 211, 1) 100%
            );
        }
    }

    .contact {
        &__hero {
            padding: var(--padding) 0 calc(var(--padding) * 2) 0;
            background-color: var(--secondary);
        }
        &__content {
            padding: var(--padding);
            @include min-vh(100);
            display: grid;

            background: linear-gradient(
                0deg,
                rgba(65, 0, 211, 0) 0%,
                rgba(65, 0, 211, 0) 85%,
                rgba(65, 0, 211, 1) 100%
            );

            .mail {
                display: flex;
                justify-content: center;
                text-align: center;
                margin: 1em 0;

                a {
                    text-decoration: none;
                }
            }
        }
    }
}
