.page {
    padding: var(--padding);
    background-color: var(--secondary);
}

.impressum,
.datenschutz {
    &__content {
        max-width: 800px;

        p {
            font-size: var(--fs-300);
            margin: 1em 0;
        }
    }
}
