@use '../abstracts/mixins' as *;

.service-page {
    position: relative;
    // background-color: var(--secondary);

    .hero {
        padding: var(--padding);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @include min-vh(100);

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            inset: 0;

            background: var(--secondary);
            background: linear-gradient(
                0deg,
                rgba(65, 0, 211, 1) 0%,
                rgba(65, 0, 211, 1) 33%,
                rgba(65, 0, 211, 1) 100%
            );
            animation: fadeOut 325ms ease-in-out forwards;

            @keyframes fadeOut {
                0% {
                    background: linear-gradient(
                        0deg,
                        rgba(65, 0, 211, 1) 0%,
                        rgba(65, 0, 211, 1) 33%,
                        rgba(65, 0, 211, 1) 100%
                    );
                }
                100% {
                    background: linear-gradient(
                        0deg,
                        rgba(65, 0, 211, 1) 0%,
                        rgba(65, 0, 211, 0) 33%,
                        rgba(65, 0, 211, 0) 100%
                    );
                }
            }
        }

        &__media {
            position: fixed;
            width: 100%;
            inset: 0;
            z-index: -1;
            object-fit: cover;
            @include vh(100);
        }

        &__content {
            position: relative;
            z-index: 1;
        }
    }

    .intro {
        padding: var(--padding);
        background-color: var(--secondary);

        &__content {
            max-width: 1000px;
        }
    }

    .gallery {
        width: 100%;
        z-index: -5;
        padding: var(--padding) 0;
        opacity: 0;
        animation: fadeIn 1s ease-in-out forwards;

        @keyframes fadeIn {
            to {
                opacity: 1;
            }
        }

        &__overlay {
            width: 100%;
            display: flex;
            gap: 2em;
            padding: var(--padding);

            .content {
                h3 {
                    font-family: var(--ff-helv-bold);
                    font-size: var(--fs-700);
                    margin: 0 0 0.25em 0;
                    line-height: 1;
                    max-width: 17.5ch;
                }

                p {
                    font-size: var(--fs-350);
                    max-width: 50ch;
                }
            }
        }

        @include devices(mobile) {
            position: fixed;
            inset: 0;
            @include vh(100);
            padding: 0;

            &__content {
                position: relative;
                top: 0;
                width: 100%;
                height: 100%;
            }

            &__item {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
            }

            &__media {
                width: 100%;
                height: 100%;
                object-fit: cover;
                background-color: var(--secondary);
            }

            &__overlay {
                position: absolute;
                height: 100%;
                inset: 0;

                .content {
                    margin-top: 33vh;
                }
            }
        }
    }

    .next-service-hero {
        padding: var(--padding);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @include min-vh(70);
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            inset: 0;

            background: var(--secondary);
            background: linear-gradient(
                0deg,
                rgba(65, 0, 211, 1) 0%,
                rgba(65, 0, 211, 1) 33%,
                rgba(65, 0, 211, 1) 100%
            );
            animation: fadeOut 325ms ease-in-out forwards;

            @keyframes fadeOut {
                0% {
                    background: linear-gradient(
                        0deg,
                        rgba(65, 0, 211, 1) 0%,
                        rgba(65, 0, 211, 1) 33%,
                        rgba(65, 0, 211, 1) 100%
                    );
                }
                100% {
                    background: linear-gradient(
                        0deg,
                        rgba(65, 0, 211, 1) 0%,
                        rgba(65, 0, 211, 0) 33%,
                        rgba(65, 0, 211, 0) 100%
                    );
                }
            }
        }

        &__media {
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
            @include vh(70);

            img,
            video {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            &-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                inset: 0;
                background-color: #dbdbdb00;
                backdrop-filter: blur(10px);
            }
        }

        &__content {
            position: relative;
            z-index: 1;

            a {
                text-decoration: none;
            }
        }
    }

    .page-transition-overlay {
        position: fixed;
        width: 100%;
        @include vh(100);
        inset: 0;
        background-color: var(--secondary);
        z-index: 10;
        pointer-events: none !important;
        transform-origin: 'top';
    }
}

.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 3000ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 3000ms;
}
