.screen {
    &__content {
        position: fixed;
        inset: 0;
        z-index: 9999;
        width: 100%;
        height: 100vh;
        pointer-events: none;
        video,
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
